var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "香港账户汇款", back: true } }),
      _c("div", { staticClass: "hk-box" }, [
        _c("div", { staticClass: "theme-color top-tips" }, [
          _vm._v(
            "从香港账户充值需要您先［换汇／购汇］再进行［汇款充值］，通常汇款后1-2个工作日到账。"
          ),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "m-tips" }, [
          _vm._v(
            "为保证客服可以及时确认您的汇款，请您在汇款附言中填写您的姓名拼音和木星国际ID："
          ),
          _c("span", { staticClass: "theme-color" }, [
            _vm._v(_vm._s(_vm.code)),
          ]),
        ]),
        _c("div", { staticClass: "title" }, [_vm._v("从香港银行账户汇款")]),
        _c("div", { staticClass: "box-items" }, [
          _c("div", { staticClass: "m-box" }, [
            _vm._m(1),
            _vm._m(2),
            _vm._m(3),
            _vm._m(4),
            _vm._m(5),
            _vm._m(6),
            _c("div", { staticClass: "flex-box" }, [
              _c("div", [_vm._v("附言")]),
              _c("div", [
                _vm._v("请填写姓名拼音和木星国际ID："),
                _c("em", { staticClass: "theme-color" }, [
                  _vm._v(_vm._s(_vm.code)),
                ]),
              ]),
            ]),
            _vm._m(7),
          ]),
        ]),
        _vm._m(8),
        _c("div", { staticClass: "bb-box" }, [
          _vm._v(
            " Corres International是客户在星展银行的资金集合托管账户。打入Corres International的资金为客户资产，与木星国际公司的资金严格隔离。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "finacing-footer" }, [
        _c(
          "div",
          { staticClass: "add-btn theme-bgcolor", on: { click: _vm.lx } },
          [_vm._v("已汇款，通知客服")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box" }, [
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("币种")]),
        _c("div", [_vm._v("美元")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("限额")]),
        _c("div", [_vm._v("无")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("手续费")]),
        _c("div", [_vm._v("约7美元（由银行收取）")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("到账时间")]),
        _c("div", [_vm._v("预计1-2个工作日")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("特点")]),
        _c("div", [_vm._v("适合大额转账充值")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("银行名称")]),
      _c("div", [_vm._v("DBS Bank Ltd,Hong Kong Branch")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("银行号码")]),
      _c("div", [_vm._v("185")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div"),
      _c("div", { staticClass: "underline" }, [
        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
        _vm._v("香港有多家DBS，请认准"),
        _c("span", { staticClass: "red" }, [_vm._v("代码185")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("SWIET码")]),
      _c("div", [_vm._v("DBSSHKHH")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("账户名称")]),
      _c("div", [_vm._v("Corres International")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("账户号码")]),
      _c("div", [_vm._v("927300132497")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div"),
      _c("div", { staticClass: "underline" }, [
        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
        _vm._v("若通过"),
        _c("span", { staticClass: "red" }, [_vm._v("工银亚洲")]),
        _vm._v("充值，请勿填写附言"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "b-tips theme-color" }, [
      _c("i", { staticClass: "el-icon-question" }),
      _vm._v(" Corres International 和木星国际是什么关系？"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }