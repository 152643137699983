<template>
  <div>
    <Header :title="'香港账户汇款'" :back="true"/>
    <div class="hk-box">
      <div class="theme-color top-tips">从香港账户充值需要您先［换汇／购汇］再进行［汇款充值］，通常汇款后1-2个工作日到账。</div>
      <div class="top-box">
        <div class="flex-box">
          <div class="theme-color">币种</div>
          <div>美元</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">限额</div>
          <div>无</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">手续费</div>
          <div>约7美元（由银行收取）</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">到账时间</div>
          <div>预计1-2个工作日</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">特点</div>
          <div>适合大额转账充值</div>
        </div>
      </div>
      <div class="m-tips">为保证客服可以及时确认您的汇款，请您在汇款附言中填写您的姓名拼音和木星国际ID：<span class="theme-color">{{code}}</span></div>
      <div class="title">从香港银行账户汇款</div>
      <div class="box-items">
        <div class="m-box">
          <div class="flex-box">
            <div>银行名称</div>
            <div>DBS Bank Ltd,Hong Kong Branch</div>
          </div>
          <div class="flex-box">
            <div>银行号码</div>
            <div>185</div>
          </div>
          <div class="flex-box">
            <div></div>
            <div class="underline"><span class="red">* </span>香港有多家DBS，请认准<span class="red">代码185</span></div>
          </div>
          <div class="flex-box">
            <div>SWIET码</div>
            <div>DBSSHKHH</div>
          </div>
          <div class="flex-box">
            <div>账户名称</div>
            <div>Corres International</div>
          </div>
          <div class="flex-box">
            <div>账户号码</div>
            <div>927300132497</div>
          </div>
          <div class="flex-box">
            <div>附言</div>
            <div>请填写姓名拼音和木星国际ID：<em class="theme-color">{{code}}</em></div>
          </div>
          <div class="flex-box">
            <div></div>
            <div class="underline"><span class="red">* </span>若通过<span class="red">工银亚洲</span>充值，请勿填写附言</div>
          </div>
        </div>
      </div>
      <div class="b-tips theme-color"><i class="el-icon-question"></i> Corres International 和木星国际是什么关系？</div>
      <div class="bb-box">
        Corres International是客户在星展银行的资金集合托管账户。打入Corres International的资金为客户资产，与木星国际公司的资金严格隔离。
      </div>
      <!-- <div class="title">如果你想办理香港账户……？</div>
      <div class="bb-box box-items">
        牛牛贴心地为你整理好八大银行开设香港账户全攻略，更为你提供木星国际用户开户专享福利，详情参见<a @click="account" class="theme-color underline">《木星国际香港银行账户专享开户》。</a>
      </div> -->
    </div>
    <div class="finacing-footer">
      <div class="add-btn theme-bgcolor" @click="lx">已汇款，通知客服</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
     code:''
    }
  },
  created(){
    let info =  JSON.parse(localStorage.getItem('info'));
    this.code = info.code 
  },
  methods:{
    lx(){
      this.$router.push({path:'banktransfer'})
    },
    account(){
      this.$router.push({path:'account'})
    }
  }
}
</script>
<style lang="scss" scoped>
.hk-box{
  background: #fff;
  color: #464646;
  padding: 2.5%;
  .top-tips{
    line-height: 2;
    font-size: .95rem;
    margin: 1% 4%;
  }
  .top-box{
    font-size: .95rem;
    margin: 2% 4% 8%;
    padding-bottom:1rem;
    border-bottom:1px solid #eee;
    .flex-box{
      margin:1.8% 0%;
      >:first-child{
        width: 80px;
        text-align: justify;
        text-align-last: justify;
        margin-right:20px
      }
    }
  }
  .m-tips{
    margin: 2% 4% 6%;
    line-height: 1.5;
    font-size: .95rem;
  }
  .m-box{
    font-size: .9rem;
    margin: 2% 4%;
    .flex-box{
      margin:1.8% 0%;
      >:first-child{
        width: 60px;
        text-align: justify;
        text-align-last: justify;
        margin-right:16px
      }
    }
  }
 
  .underline{
    text-decoration: underline;
    font-size: .8rem;
  }
 .b-tips{
  margin: 4% 4% 2%;
 }
 .b-box{
  margin: 4% 4% 2%;
  font-size: .95rem;
 }
 .bb-box{
  font-size: .95rem;
  margin: 4% 4% 2%;
  margin-bottom:50px
 }
}
.finacing-footer{
  background: #fff;
  position: fixed;
  bottom: 0px;
  width: 95%;
  padding: 2.5%;
  .add-btn{
    border-radius:8px;
    width: 100%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
</style>